import { IIcon } from "../../utils";

import { IBaseEntity } from "./base_entity";
import { IPaginationResponseDto } from "./global";
import { IImageDto, IProduct, IVariantDto } from "./product";
import { IProductShipmentDto } from "./shipment";
import { IUser } from "./user";

export enum PaymentMethodEnum {
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  PIX = "PIX",
  BOLETO = "BOLETO",
}

export enum CardBrandEnum {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  ELO = "ELO",
  DISCOVER = "DISCOVER",
  AMEX = "AMEX",
  HIPERCARD = "HIPERCARD",
  JCB = "JCB",
  AURA = "AURA",
  DINERS = "DINERS",
}

export const paymentMethodTranslationPerMethod: Record<PaymentMethodEnum, string> = {
  BOLETO: "Boleto Bancário",
  DEBIT_CARD: "Cartão de Débito",
  CREDIT_CARD: "Cartão de Crédito",
  PIX: "Pix",
};

export const cardBrandEnumPerPackageBrand: Partial<Record<string, CardBrandEnum>> = {
  AMERICANEXPRESS: CardBrandEnum.AMEX,
  DINERSCLUB: CardBrandEnum.DINERS,
};

const iconPerCardBrandRecord: Record<CardBrandEnum, IIcon> = {
  VISA: { name: "visa", source: "assets", extension: "svg", size: "60px" },
  MASTERCARD: { name: "mastercard", source: "assets", extension: "svg", size: "44px" },
  ELO: { name: "elo", source: "assets", extension: "svg", size: "74px" },
  DISCOVER: { name: "discover", source: "assets", extension: "svg", size: "44px" },
  AMEX: { name: "amex", source: "assets", extension: "svg", size: "44px" },
  HIPERCARD: { name: "hipercard", source: "assets", extension: "svg", size: "54px" },
  AURA: { name: "", source: "assets", extension: "svg", size: "54px" },
  DINERS: { name: "", source: "assets", extension: "svg", size: "54px" },
  JCB: { name: "", source: "assets", extension: "svg", size: "54px" },
};

export function iconPerCardBrand(brand: CardBrandEnum, sizeFactor = 1): IIcon {
  const icon = { ...iconPerCardBrandRecord[brand] };
  icon.size = `${parseInt(icon.size!) * sizeFactor}px`;

  return icon;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export interface IStatusStep<T extends Object> {
  status: T;
  date: Date;
}

export interface IPixQRCodeDto {
  encodedImage: string;
  payload: string;
  expirationDate: string;
}

export interface IBankSlipDto {
  identificationField: string;
  gatewayNumber: string;
  url: string;
  barCode: string;
  dueDate: string;
}

export enum OrderStatusEnum {
  WAITING_CONFIRMATION = "WAITING_CONFIRMATION", // Esperando processamento do pagamento
  CONFIRMED = "CONFIRMED", // Processo de entrega dos itens
  FAILED = "FAILED", // Por algum motivo o pedido não avançou (tipicamente reembolso ou cobrança vencida)
  SUCCESS = "SUCCESS", // Todos os itens foram entregues
}

export enum OrderProductStatusEnum {
  IDLE = "IDLE",
  PREPARING = "PREPARING", // Produto foi recomprado no e-commerce fonte
  DELIVERING = "DELIVERING",
  DELIVERED = "DELIVERED",
}

export enum OrderChargeStatusEnum {
  PENDING = "PENDING", // Aguardando pagamento
  CONFIRMED = "CONFIRMED", // Pagamento processado pela gateway de pagamento
  OVERDUE = "OVERDUE", // Cobrança vencida
  REPROVED_BY_RISK_ANALYSIS = "REPROVED_BY_RISK_ANALYSIS",
  REFUND_REQUESTED = "REFUND_REQUESTED",
  REFUND_IN_PROGRESS = "REFUND_IN_PROGRESS",
  REFUNDED = "REFUNDED",
}

export interface IOrderCharge extends IBaseEntity {
  order: IOrder;
  chargeId: string;
  installmentId: string | null;
  installmentNumber: number | null;
  value: number;
  netValue: number;
  description: string | null;
  status: OrderChargeStatusEnum;
  statusHistory: IStatusStep<OrderChargeStatusEnum>[];
  pix?: IPixQRCodeDto | null;
  bankSlip?: IBankSlipDto | null;
  bankSlipUrl?: string | null;
  finePercentage: number;
  interestPercentage: number;
  dueDate: Date;
  paymentDate: Date | null;
  invoiceUrl: string;
  transactionReceiptUrl: string | null;
}

export interface IOrderProduct extends IBaseEntity {
  product: IProduct;
  order: IOrder;

  /**
   * Hash gerado do sexshop do produto. Essa propriedade é gerada após solicitar
   * todos os pedidos do usuário e é usada para separar cada orderProduct por sexshop,
   * mantendo o sexshop em sigilo.
   * @type {string | null}
   */
  hash?: string | null;

  supplierOrder: any | null;
  image: IImageDto | null;
  quantity: number;
  metadata: IVariantDto["metadata"] | null;
  variantId: string | null;
  defaultVariantId: string | null;
  name: string;
  price: number;
  offerPrice: number;
  supplierPrice: number;
  supplierOrderCode: string | null;
  estimatedDeliveryDate: Date;
  status: OrderProductStatusEnum;
  statusHistory: IStatusStep<OrderProductStatusEnum>[];
  deliveredDate: Date | null;
  shipmentBusinessDays: number;
  shipmentLabel: string;
  deliveryTrackUrl: string | null;
}

export interface IOrderCoupon {
  id: string;
  code: string;
}

export interface IOrder extends IBaseEntity {
  freightDiscountPercentage: number;
  user: IUser | null;
  products: IOrderProduct[];
  charges: IOrderCharge[];
  suppliersOrders: any[];
  shipmentCode: string;
  freightPrice: number;
  subtotal: number;
  total: number;
  freightDiscount: number;
  itemsDiscount: number;
  totalDiscount: number;
  paymentMethod: PaymentMethodEnum;
  cardLastNumbers?: string | null;
  cardBrand?: CardBrandEnum | null;
  installments: number | null;
  installmentValue: number | null;
  status: OrderStatusEnum;
  statusHistory: IStatusStep<OrderStatusEnum>[];
  deliveryRecipientName: string;
  electronicInvoiceUrl: string | null;
  couponDiscount: number;
  couponDiscountPercentage: number;
  coupon?: IOrderCoupon | null;

  // Delivery Address

  deliveryStreet: string;
  deliveryNumber: string | "N/A";
  deliveryCity: string;
  deliveryState: string;
  deliveryPostalCode: string;
  deliveryComplement?: string | null;
  deliveryNeighborhood: string;
}

export type IOrderPaginationResponseDto = IPaginationResponseDto<IOrder>;

export interface ICreateOrderDto {
  method: PaymentMethodEnum;
  deliveryAddressId: string;
  cardId?: string;
  cardCVV?: string;
  installments?: number;
  items: IProductShipmentDto[];
  cpf?: string;
  couponCode?: string;
}

export interface ICreateOrderResponseDto {
  pix?: IPixQRCodeDto;
  bankSlip?: IBankSlipDto;
  order: IOrder;
  orderProducts: IOrderProduct[];
}
