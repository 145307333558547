import {
  LocationQueryRaw,
  NavigationGuard,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";

import { useAuthStore } from "../store/auth";
import { useCartStore } from "../store/cart";
import {
  DEFAULT_META_DESCRIPTION,
  getIndividualItemFromQuery,
  getQueryFromIndividualItem,
  waitForCartItemsLoad,
} from "../utils";

const RESET_PASSWORD_META_DESCRIPTION =
  "Não perca dados com suas preferências e altere a senha da sua conta para acessa-la novamente.";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("../components/LayoutNavbarHorizontalAndFooter.vue"),
    children: [
      {
        path: "cadastro",
        component: () => import("../views/user/SignUp.vue"),
        meta: {
          requiresUnauth: true,
          title: "Cadastro",
          seoMeta: {
            description:
              "Faça seu cadastro no maior sexshop do Brasil! Na Nobelle não há produto em falta. Pensou em algo? Você acha aqui!",
          },
        },
      },
      {
        path: "login",
        component: () => import("../views/Login.vue"),
        meta: {
          requiresUnauth: true,
          title: "Login",
          seoMeta: {
            description:
              "Faça seu login no maior sexshop do Brasil! Na Nobelle não há produto em falta. Pensou em algo? Você acha aqui!",
          },
        },
      },
      {
        path: "/redefinir-senha",
        component: () => import("../views/ResetPassword.vue"),
        meta: {
          requiresUnauth: true,
          title: "Redefinir senha com código",
        },
      },
      {
        path: "redefinicao-de-senha",
        component: () => import("../views/RequestPasswordReset.vue"),
        meta: {
          requiresUnauth: true,
          title: "Redefinir senha",
          seoMeta: { description: RESET_PASSWORD_META_DESCRIPTION },
        },
      },
      {
        path: ":pathMatch(.*)*",
        name: "not-found",
        component: () => import("../views/user/Error404.vue"),
        meta: { title: "Não encontrada" },
      },
      {
        path: "404",
        name: "not-found",
        component: () => import("../views/user/Error404.vue"),
        meta: { title: "Não encontrada" },
      },
      {
        path: "",
        name: "home",
        component: () => import("../views/user/Home.vue"),
        meta: { seoMeta: { description: DEFAULT_META_DESCRIPTION } },
      },
      {
        path: "produto/:slug",
        name: "product",
        component: () => import("../views/user/Product.vue"),
      },
      {
        path: ":categories+",
        name: "products-list",
        component: () => import("../views/user/ProductsList.vue"),
      },
      {
        path: "marca/:brand",
        name: "brand-products-list",
        component: () => import("../views/user/ProductsList.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("../components/LayoutMyAccount.vue"),
    children: [
      {
        path: "minha-conta",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Minha Conta",
        },
      },
      {
        path: "meus-pedidos",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Meus Pedidos",
        },
      },
      {
        path: "favoritos",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Favoritos",
        },
      },
      {
        path: "enderecos",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Endereços",
        },
      },
      {
        path: "meus-cartoes",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Meus Cartōes",
        },
      },
      {
        path: "alterar-senha",
        component: () => import("../views/user/MyAccount.vue"),
        meta: {
          requiresAuth: true,
          title: "Alterar Senha",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("../components/LayoutHelp.vue"),
    children: [
      {
        path: "ajuda",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Perguntas Frequentes",
          seoMeta: { description: "Veja nosso FAQ antes de entrar em contato!" },
        },
      },
      {
        path: "ajuda-meus-pedidos",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Meus Pedidos",
          seoMeta: { description: "Tire dúvidas relacionadas aos seus pedidos." },
        },
      },
      {
        path: "ajuda-rastrear",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Rastrear Meu Pedido",
          seoMeta: { description: "Entenda como funciona o rastreio do seu pedido." },
        },
      },
      {
        path: "ajuda-prazos",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Prazo de Envio e Entrega",
          seoMeta: { description: "Informações sobre prazos." },
        },
      },
      {
        path: "ajuda-trocas",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Troca, Desistência e Devolução",
          seoMeta: { description: "Me arrependi de uma compra, o que fazer?" },
        },
      },
      {
        path: "ajuda-pagamento",
        component: () => import("../views/user/Help.vue"),
        meta: {
          title: "Meios de Pagamento",
          seoMeta: {
            description:
              "A Nobelle ofere todos os meios de pagamento mais relevantes para o seu conforto, confira!",
          },
        },
      },
    ],
  },
  {
    path: "/checkout",
    component: () => import("../views/user/checkout/LayoutCheckout.vue"),
    children: [
      {
        alias: ["/checkout"],
        path: "dados-pessoais",
        component: () => import("../views/user/checkout/PersonalData.vue"),
        meta: { title: "Checkout | Dados pessoais", checkoutStep: 1 },
        beforeEnter: async (to, from) =>
          checkoutBeforeEnterBase(to, from, (query) => {
            const authStore = useAuthStore();
            const cartStore = useCartStore();

            if (authStore.isUserLoggedIn) {
              return cartStore.checkoutAddress
                ? { path: "/checkout/finalizar", query }
                : { path: "/checkout/envio", query };
            }
          }),
      },
      {
        path: "envio",
        meta: { title: "Checkout | Envio", checkoutStep: 2 },
        component: () => import("../views/user/checkout/Shipping.vue"),
        beforeEnter: async (to, from) =>
          checkoutBeforeEnterBase(to, from, (query) => {
            const authStore = useAuthStore();

            if (!authStore.isUserLoggedIn) {
              return { path: "/checkout", query };
            }
          }),
      },
      {
        path: "finalizar",
        name: "finalizar-checkout",
        meta: { title: "Checkout | Pagamento", checkoutStep: 3 },
        component: () => import("../views/user/checkout/Payment.vue"),
        beforeEnter: async (to, from) =>
          checkoutBeforeEnterBase(to, from, (query) => {
            const authStore = useAuthStore();
            const cartStore = useCartStore();

            if (!authStore.isUserLoggedIn) {
              return { path: "/checkout", query };
            }

            if (!cartStore.checkoutAddress) {
              return { path: "/checkout/envio", query };
            }
          }),
      },
    ],
  },
  {
    path: "/pedido-realizado",
    meta: { title: "Pedido realizado", requiresAuth: true },
    component: () => import("../views/user/checkout/Success.vue"),
    beforeEnter: () => {
      const cartStore = useCartStore();
      if (!cartStore.orderResult) {
        return "/";
      }
    },
  },
];

async function checkoutBeforeEnterBase(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  callback: (query: LocationQueryRaw) => ReturnType<NavigationGuard>
): Promise<ReturnType<NavigationGuard>> {
  const individualItem =
    getIndividualItemFromQuery(from.query) ?? getIndividualItemFromQuery(to.query);

  await waitForCartItemsLoad();
  const cartStore = useCartStore();
  if (!individualItem && !cartStore.checkoutItems?.length) {
    // Exibe carrinho na sidebar sem itens
    cartStore.isMenuOpen = true;

    return { path: "/" };
  }

  if (!from.path.includes("/checkout")) {
    // Se veio de qualquer outra página não relacionada ao checkout, limpa o shipment
    cartStore.shipment = null;
  }

  return callback(individualItem ? getQueryFromIndividualItem(individualItem) : {});
}

export default routes;
