import dynamicRoutes from "@dynamicRoutes";
import NProgress from "nprogress";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import { PermissionModuleEnum } from "../api/models/permission";
import { useAuthStore } from "../store/auth";
import { useUserStore } from "../store/user";
import {
  getSingleQueryValue,
  removeDisposablesQueryParams,
  resetBlockersAndShowGlobalScroll,
  sexshopBrandName,
  toast,
  updateMetaTags,
} from "../utils";

const sharedRoutes: RouteRecordRaw[] = [];
const routes = [...sharedRoutes, ...dynamicRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    if (to.hash !== from.hash) {
      const navbarHeight = document.getElementById("navbar")?.clientHeight ?? 0;

      return {
        el: to.hash,
        top: navbarHeight,
        behavior: "smooth",
      };
    } else if (to.path !== from.path) {
      return { top: 0 };
    }
  },
});

export function getRedirectParam() {
  const redirectPath = router.currentRoute.value.query["redirect-to"];

  return getSingleQueryValue(redirectPath);
}

export function routeToRedirectParamOrInitialLoggedInRoute() {
  router.push(getRedirectParam() ?? useUserStore().initialLoggedInRoute);
}

function generateFlatRoutes(rawRoutes: RouteRecordRaw[], parentRoute?: RouteRecordRaw) {
  const localFlatRoutes: RouteRecordRaw[] = [];

  rawRoutes.forEach((route) => {
    if (route.children) {
      localFlatRoutes.push(...generateFlatRoutes(route.children, route));
    } else {
      if (parentRoute) {
        route.path = `${parentRoute.path}/${route.path}`.replace(new RegExp("/{2,}", "gm"), "/");
        Object.assign(route, {
          meta: {
            ...route.meta,
            permissions: [
              ...(route.meta?.permissions || []),
              ...(parentRoute.meta?.permissions || []),
            ],
          },
        });
      }
      localFlatRoutes.push(route);
    }
  });

  return localFlatRoutes;
}

export const flatRoutes = generateFlatRoutes(routes);

router.beforeEach(async (to, from) => {
  if (from.path !== to.path) {
    NProgress.start();
  }

  const authStore = useAuthStore();

  const tokenParam = to.query["google-oauth-local-token"]?.toString();
  const errorParam = to.query.error?.toString();

  if (errorParam) {
    toast("error", errorParam);
  }

  // Coleta o token gerado pelo Google Oauth do processo de sign in e realiza a autenticação
  if (tokenParam) {
    const token = decodeURIComponent(tokenParam);
    await authStore.authenticateWithGoogle(token).catch((error) => {
      toast("error", error.message);
    });
  }

  if (to.meta.requiresUnauth && authStore.isUserLoggedIn) {
    return from.fullPath;
  }

  let title: string | undefined;
  const userStore = useUserStore();
  if (
    to.name === "brand-products-list" ||
    to.name === "product" ||
    (to.name === "products-list" && [from.path, to.path].includes("/busca"))
  ) {
    // Não troca de título caso seja página de produto e espera o produto carregar, pois é definido internamente
    // Não troca de título caso seja /busca, pois o título é definido internamente independente de currentCategoryTranslation
  } else if (to.name === "products-list" && userStore.currentCategoryTranslation) {
    title = userStore.currentCategoryTranslation;
  } else {
    title = to.meta.title || `${sexshopBrandName} | Você merece mais`;
  }

  updateMetaTags({ title, description: to.meta.seoMeta?.description });

  userStore.prevPath = from.path;

  if (from.path !== to.path) {
    resetBlockersAndShowGlobalScroll();
  }

  if (to.name === "not-found") {
    return;
  }

  if (to.meta.requiresAuth && !authStore.isUserLoggedIn) {
    return "/login";
  }

  await userStore.loadUserData();
  userStore.isMobileMenuOpen = false;

  const doesNeedDashAccess = import.meta.env.VITE_PROJECT_TYPE?.toString() === "admin";
  const hasDashAccess = userStore.checkPermissions([{ module: PermissionModuleEnum.DASH }]);
  //checar permissão à página

  if (!userStore.checkPermissions(to.meta.permissions ?? [])) {
    if (doesNeedDashAccess && !hasDashAccess) {
      authStore.clearSession();

      return "/login";
    } else {
      await userStore.reloadUserData();
    }

    return "/404";
  }
});

router.afterEach(() => {
  NProgress.done();

  removeDisposablesQueryParams();
});

export default router;
